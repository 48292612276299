import "../styles/main.css"; 
import React from 'react'; 
import { Navigate } from 'react-router-dom';
import header from "../assets/faqheader.jpg"; 

function FAQ() {

  if (!(sessionStorage.getItem('auth-token') == 'hobie') && !(sessionStorage.getItem('auth-token') == 'hobie') )
  {
    return (
      <React.Fragment>
        <Navigate to="/pass"/>
      </React.Fragment>
      ); 
  }

	return (
    <React.Fragment>
      <div className=""> 
        <img className="headerImage" src={header} alt="westman"/>
      </div>
      <div className="headerText">
      FAQ

      </div>
      <div className="row"> </div>
        <div className="row">
          <div className="faqColumnL">
            <div className="faqsubheader">
            Are children invited?
            </div>
          </div>
          <div className="faqColumn">
            <div className="faqinfo">
              While we love your children, we ask you to please leave them at home for the event.
            </div>
          </div>
        </div>

      <div className="row"> </div>
        <div className="row">
          <div className="faqColumnL">
            <div className="faqsubheader">
            What is the dress code?
            </div>
          </div>
          <div className="faqColumn">
            <div className="faqinfo">
              Dressy casual attire. We would also request that women wear flats, as the event will take place outdoors on uneven ground. 
            </div>
          </div>
        </div>
      <div className="row"> </div>
        <div className="row">
          <div className="faqColumnL">
            <div className="faqsubheader">
            What is the schedule?
            </div>
          </div>
          <div className="faqColumn">
            <div className="faqinfo">
              As we get closer to the event, we will post a full schedule. As of now, the welcome party will be early evening on Friday, June 16th, the wedding will be the afternoon of the following day, and there will be a brunch Sunday morning.
            </div>
          </div>
        </div>
    </React.Fragment>
  ); 
}

export default FAQ; 