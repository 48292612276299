import logo from "./logo.svg";
import React from "react"; 
import "./App.css";
import { browserHistory } from 'react-router';
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar.js";
import Registry from "./pages/Registry"; 
import Schedule from "./pages/Schedule"; 
import Travel from "./pages/Travel"; 
import WeddingParty from "./pages/WeddingParty";
import Home from "./pages"; 
import Accommodations from "./pages/Accommodations"; 
import Activities from "./pages/Activities"; 
import Footer from "./components/Footer"; 
import RSVP from "./pages/RSVP"; 
import Login from "./pages/Login"; 
import FAQ from "./pages/FAQ"; 

const authToken = 'hobie';

function App() {

  if (!(sessionStorage.getItem('auth-token') ) )
  {
    return (
      <React.Fragment>
      <Router>
      <div className="App">
        <Routes>
          <Route index path="/" element={<Home />} ></Route>
          <Route path="/pass" element={<Login />}></Route>
        </Routes>
        </div>
        </Router> 
      </React.Fragment>

      ); 
  }
  else{
    return (
    <React.Fragment>
      <Router>
      <div className="App">
      <header >
        <Navbar />
      </header> 
        <Routes>
          <Route index path="/" element={<Home />} ></Route>
          <Route path="/travel" element={<Travel />} ></Route>
          <Route path="/accommodations" element={<Accommodations />} ></Route>
          <Route path="/mv" element={<Activities />} ></Route>
          <Route path="/registry" element={<Registry />} ></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/schedule" element={<Schedule/>}></Route>
          <Route path="/pass" element={<Login />}></Route>
        </Routes>
        <Footer />
      </div>
      </Router>
</React.Fragment>
  );
  }

  
}

export default App;
