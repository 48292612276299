import React, { useState } from "react"; 
import { Link, Navigate, useNavigate, redirect } from "react-router-dom"; 
import "../styles/main.css"; 
import Hobie from "../assets/Hobie.png";
import FlowerCluster from "../assets/FlowerCluster.png";
import md5 from 'md5'; 


const Login = () => {

    const navigate = useNavigate();

    const [passwordInput, setPasswordInput] = useState('');

    const handlePasswordChange = (e) => {
        setPasswordInput(e.target.value);
    }

    const handleLoginSubmit = (e) => {
        e.preventDefault(); 
        let hardcodedCred = {
            password: '25ab4dfef58d2c426b7c31dd6d8e9511'
        }

        if ((md5(passwordInput) == hardcodedCred.password)) {
            //combination is good. Log them in.
            //this token can be anything. You can use random.org to generate a random string;
            const token = 'hobie';
            console.log("Submitted password: ")
            console.log(passwordInput)
            sessionStorage.setItem('auth-token', token);
            navigate("/"); 

        } else {
            //bad combination
            alert('Incorrect password');
        }
    };

    return (
        <div className="login-page form">
        <div className="rowlogin">
        <div className="Lthird" >
          <img src={FlowerCluster} alt="flowers" className="flowerL"/>
        </div>
        <div className="Mthird">
            <img src={Hobie} alt="Hobie" className="hobie"/>
        </div>
        <div className="Rthird">
          <img src={FlowerCluster} alt="flowers" className="Rimage"/>
        </div>
        </div>
            <form autoComplete="off" onSubmit={handleLoginSubmit}>
                <div className="form-group pad-bottom">
                    <input
                    type="password"
                    autoComplete="new-password"
                    className="form-control userinput"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    value={passwordInput}
                    onChange={handlePasswordChange}
                    />
                </div>
                <button type="submit" className="btn btn-primary hobieButton">
                    Submit
                </button>
          </form>
        </div>
      );
}

export default Login; 