import '../App.css';

function WeddingParty() {
	return (
		<div>
		Test
		Test
		Test
		Test
		Test
		Test
		Test
		Test
		Test
		</div>
		); 
}

export default WeddingParty; 