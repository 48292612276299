import React, {useState} from 'react';
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  Dropdown,
  NavLink2
} from './NavbarElements';
import {Link} from 'react-router-dom';
import monogram from "../../assets/monogram.png"
import "../../styles/main.css";
  
const Navbar = () => {
  const [dropdown, setDropdown] = useState(false);
  const showDropdown = () => 
  { console.log(dropdown); 
    setDropdown(!dropdown);}

  const toggle = () => {
    setDropdown(!dropdown);
  }

  return (
    <>
      <Nav>
        <img src={monogram} href="/" className="headerImg"/>
        <Bars onClick={showDropdown} />
        <NavMenu>
          <NavLink to='/' activeStyle>
            Home
          </NavLink>
          <NavLink to='/schedule' activeStyle>
            Schedule
          </NavLink>
          <NavLink to='/registry' activeStyle>
            Registry
          </NavLink>
          <NavLink to='/mv' activeStyle>
            MV
          </NavLink>
          <NavLink to='/travel' activeStyle>
            Travel
          </NavLink>
          <NavLink to='/accommodations' activeStyle>
            Accommodations
          </NavLink>
        </NavMenu>
      </Nav>

      <Dropdown dropdown={!dropdown}>
        <div className="dropdownItem">
          <NavLink2 to='/' dropdown={!dropdown} onClick={toggle} activeStyle>
            Home
          </NavLink2>
          </div>
          <div className="dropdownItem">
          <NavLink2 to='/schedule' dropdown={!dropdown} onClick={toggle} activeStyle>
            Schedule
          </NavLink2>
          </div>
          <div className="dropdownItem">
          <NavLink2 to='/registry' dropdown={!dropdown} onClick={toggle} activeStyle>
            Registry
          </NavLink2>
          </div>
          <div className='dropdownItem'>
          <NavLink2 to='/mv' dropdown={!dropdown} onClick={toggle} activeStyle>
            MV
          </NavLink2>
          </div>
          <div className="dropdownItem">
          <NavLink2 to='/travel' dropdown={!dropdown} onClick={toggle} activeStyle>
            Travel
          </NavLink2>
          </div>
          <div className="dropdownItem">
          <NavLink2 to='/accommodations' dropdown={!dropdown} onClick={toggle} activeStyle>
            Accommodations
          </NavLink2>
          </div>
        </Dropdown>
    </>
  );
};
  
export default Navbar;