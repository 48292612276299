import "../styles/main.css"; 
import React, { useState, useEffect } from 'react'; 
import { Navigate } from 'react-router-dom';
import md5 from 'md5'; 

function RSVP() {

 //  const [hashInput, setHashInput] = useState('');
 //  const [guestOutput, setGuestOutput] = useState('')
 //  const [guestCount, setGuestCount] = useState('')

	// const handleinput = (e) => {
	//     setHashInput(e.target.value);
	// }

	// const handleLoginSubmit = (e) => {
	//     e.preventDefault(); 

	//     fetch("./guests.json")
	//     	.then(
	//     		function(result){
	//     			return result.json()
	//     		})
	//     	.then(
	//     		function(js){
	//     			let val = js[md5(hashInput)]; 
	//     			setGuestOutput([...Array(val['guests'] + 1).keys()]);
	//     		});
	//     };

	// const handleGuestCount = (e) => {
	//     setGuestCount(e.target.value);
	// }

	// const handleGuestList = (e) => {
	// 	e.preventDefault(); 
	// 	console.log(md5(hashInput))
	// 	fetch("./guests.json")
	// 		.then(
	// 			function(result){
	// 				return result.json()
	// 			})
	// 		.then(
	// 			function(js){
	// 				js[md5(hashInput)] = guestCount; 
	// 				fs.writeFile('guests.json', js, 'utf8')
	// 			})
	// }

	// if (!(sessionStorage.getItem('auth-token') == 'hobie') && !(sessionStorage.getItem('auth-token') == 'hobie') )
	// {
	// return (
	//   <React.Fragment>
	//     <Navigate to="/pass"/>
	//   </React.Fragment>
	//   ); 
	// }

	// if (guestOutput)
	// {
	// 	return (
	// 	<React.Fragment>
	// 		<div className="placeholder"> 
	// 	        <form autoComplete="off" onSubmit={handleLoginSubmit}>
	// 	            <div className="form-group pad-bottom">
	// 	                <input
	// 	                type="text"
	// 	                autoComplete="new-password"
	// 	                className="form-control userinput"
	// 	                id="hash"
	// 	                placeholder="Password"
	// 	                value={hashInput}
	// 	                onChange={handleinput}
	// 	                />
	// 	            </div>
	// 	            <button type="submit" className="btn btn-primary hobieButton">
	// 	                    Submit
	// 	            </button>
	// 	        </form>

	// 	    <div>
	// 	    	<form autoComplete="off" onSubmit={handleGuestList}>
	// 	            <div className="form-group pad-bottom">
	// 	                <select name="Attending" id="test" onChange={handleGuestCount} value={guestCount}>
	// 	                	{guestOutput.map(elem => {
	// 	                		return <option value={elem} className="hobieButton">{elem}</option>
	// 	                	})}
	// 	                </select>
	// 	            </div>
	// 	            <button type="submit" className="btn btn-primary hobieButton">
	// 	                    Submit
	// 	            </button>
	// 	            </form>
	// 	    </div>
	// 		</div>
	// 	</React.Fragment>
	// 	); 
	// }
	// else
	// {
	// 	return (
	// 	<React.Fragment>
	// 		<div className="placeholder"> 
	// 	        <form autoComplete="off" onSubmit={handleLoginSubmit}>
	// 	            <div className="form-group pad-bottom">
	// 	                <input
	// 	                type="text"
	// 	                autoComplete="new-password"
	// 	                className="form-control userinput"
	// 	                id="hash"
	// 	                placeholder="Password"
	// 	                value={hashInput}
	// 	                onChange={handleinput}
	// 	                />
	// 	            </div>
	// 	            <button type="submit" className="btn btn-primary hobieButton">
	// 	                    Submit
	// 	            </button>
	// 	        </form>
	// 		</div>
	// 	</React.Fragment>
	// 	);
	// }
	
}

export default RSVP; 