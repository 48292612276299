import React from 'react';
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
} from './Navbar/NavbarElements';
import "../styles/main.css"; 


function Footer() {

	return (

		<React.Fragment>
		  <div className="footer"> </div>

		</React.Fragment>

		); 
}

export default Footer; 