import React from "react";
import "../styles/main.css" 
import { Navigate } from 'react-router-dom';
import schedule from "../assets/schedule.jpeg";

function Schedule(){

	if (!(sessionStorage.getItem('auth-token') == 'hobie') && !(sessionStorage.getItem('auth-token') == 'hobie') )
	{
		return (
		<React.Fragment>
			<Navigate to="/pass"/>
		</React.Fragment>
		); 
	}

		return (
		<React.Fragment>

			<div >
			<img className="headerImage" src={schedule} alt="schedule"/>
			</div> 

			<div className="headerText">
			Event Schedule
			</div>
		<div/>

		<div className="scheduleBlock">
			<div className="scheduleSubheader">Royal and Ancient</div>
			<div className="schedulePlace">Royal and Ancient Chappaquiddick Links</div>
			<div className="scheduleTime"><b><i>Friday, 12:00 PM</i></b></div>
			<div className="scheduleSubheader"></div>
			<div className="info"> For those arriving to the island early, feel free to join the groom and friends for a casual round at the Royal and Ancient Golf Club on Chappy. Think Danny for attire, not Smails.  </div>
		</div>

		<div className="scheduleBlock">
			<div className="scheduleSubheader">Welcome Party</div>
			<div className="schedulePlace">81 S Water Street, Edgartown</div>
			<div className="scheduleTime"><b><i>Friday, 6:30 PM</i></b></div>
			<div className="scheduleSubheader"></div> 
			<div className="activityLoc">Join us for drinks, heavy apps, and sweet treats to kick off the celebrations. If you are not in walking distance, you can give Stagecoach Taxi a call at 508-627-4566.</div>
			<div className="scheduleSubheader"></div>
			<div className="info"> Kick off Will and Sarah's Wedding Weekend with a "Welcome to the Island" cocktail party. Join us for an evening of chatting, eating, "cheers-ing", and music in anticipation of "the big day." Our favorite people will have traveled from near and far and we can't wait for everyone to meet! </div>
			<div className="info">
			Heavy hors d'oeuvres will be served, so feel free to make dinner out of it. However, if you are a traditionalist who prefers a sitdown meal, or are prone to "hangriness" like Sarah, feel free to eat dinner prior to the party. 
			</div>
			<div className="info">
			The party will take place at the 81 South Water Street House, which is on the water, so it may get chilly as we continue celebrating into the night. The house is located walking distance from Downtown Edgartown. If coming from downtown, walk down South Water Street and look for a tent on the left (harborside). Celebrations will occur in the side yard of the home, so ladies, please consider this when selecting shoes for the evening. 
			</div>
			<div className="info">
			Be wary of "popping the clutch" and risking a hangover for main event. In order to help prevent overexcitedness, we plan to say goodnight around 10pm. Do not fret, this is only the beginning. </div>
		</div>

		<div className="scheduleBlock">
			<div className="scheduleSubheader">Ceremony and Champagne Toast</div>
			<div className="schedulePlace">81 S Water Street, Edgartown</div>
			<div className="scheduleTime"><b><i>Saturday, 2:00 PM</i></b></div>
			<div className="scheduleSubheader"></div>
			<div className="activityLoc">Please arrive by 2PM to leave time for a pre-ceremony glass of bubbles before finding your seat. For those not staying within walking distance from the ceremony location, transportation will be scheduled and provided through Stagecoach Taxi.</div>
			<div className="scheduleSubheader"></div>
			<div className="info"> The big day is finally here! The ceremony will take place at the 81 South Water Street House, the same location as the welcome party. Hopefully everyone is famililar with the location at this point, but if you do not remember how to get to or from the house, we won't judge you, we will just blame it on the alcohol or salty air. The tent from the night before will still be up, so look out for that as an obvious landmark.
			</div>
			<div className="info">
			The ceremony begins at 2:30, so please try to arrive in advance. As you make your way into the yard through the gates, guests will walk through the tent and down a fairly steep grassy hill. Please consider this harrowing trek when selecting footwear. We will have seating available at the top of the hill for those who are more past their daredevil days and are not willing to risk the fall. Please feel free to grab a glass of champagne as you get settled and wait for the ceremony to begin.
			</div>
			<div className="info">
			After the knot is tied, there will be a toast to Sarah and Will. The couple will take a brief moment to celebrate their new life together just the two of them before rounding everyone up to begin the parade to the Chappy Ferry.
			
			 </div>
		</div>

		<div className="scheduleBlock">
			<div className="scheduleSubheader">Parade to Chappy Ferry</div>
			<div className="schedulePlace">81 S Water Street, Edgartown</div>
			<div className="scheduleTime"><b><i>Saturday, 4:00 PM</i></b></div>
			<div className="scheduleSubheader"></div> 
			<div className="activityLoc">Wear or bring comfortable shoes as the bride and groom lead us through historic Edgartown to the Chappy Ferry (0.4 miles, a ~7 minute walk). Taxis will be on the Chappy side to transport you to the reception venue. </div>
			<div className="scheduleSubheader"></div>
			<div className="info">At around 4pm, guests will be lead by small marching ensemble of musicians back down South Water Street to Main Street parade-style, finally arrving to the Chappy Ferry. The walk is 0.4 miles and will take approximately 10 minutes, so consider this when selecting shoes for the day. 
			</div>
			<div className="info">
			The Chappy Ferry is a small, open air boat. It takes around 2 and a half minutes to get from the Edgartown side to Chappaquiddick where the reception will be. Ferry fare will be covered, so as you approach the boat, let the captain or attendant know you are with the wedding party.
			</div>
			<div className="info">
			Once across the harbor and on Chappy, send off the newly weds before getting into a taxi, which will be waiting for guests at the ferry landing on the Chappy side. It is a short, but bumby, ride to the Royal and Anctiet Chappaquiddick links where the party will continue... </div>
		</div>

		<div className="scheduleBlock">
			<div className="scheduleSubheader">Reception</div>
			<div className="schedulePlace">Royal and Ancient Chappaquiddick Links</div>
			<div className="scheduleTime"><b><i>Saturday, 5:00 PM</i></b></div>
			<div className="scheduleSubheader"></div> 
			<div className="activityLoc">Join us under the tent for toasts, dinner, and dancing! Taxis will be on site at 10PM to shuttle guests to the Chappy Ferry, and there will be Taxis waiting on the Edgartown-side for any guests staying outside of downtown Edgartown.</div>
			<div className="scheduleSubheader"></div>
			<div className="info"> The Royal and Ancient Chappaquiddick Links is the second oldest golf course in America. While that may sound grand, and it is beautiful, the "clubhouse" is an 8x8 shed with an honor system beer fridge, so we will be outdoors under a tent on the fairway of the first tee. There will be solid flooring once under the tent, but the walk from the taxi drop-off location and the main bar area will be on putting greens.
			</div>
			<div className="info">
			After an hour of cocktailing, some passed light bites, and basking in the newly wed glow with Sarah and Will, we will be seated for dinner around 6:30pm. Dinner will have fish and meat on one plate but there will be a vegetarian option for those in need.
			</div>
			<div className="info">
			Post-seated dinner, we will dance the night away until the 10pm town noise ordinance comes into effect, at which point we will head back to Edgartown in the taxis. If you do not want to stay until the bitter end, the taxi service will be running throughout the night.
			</div>
			<div className="info">
			While the official wedding activities will be over for the evening, last call at the Edgartown bars is at 1:15am, so feel free to continue toasting the couple at some of our favrotie watering holes such as the Port Hunter, the Seafood Shanty, the Atlantic, or the Wharf.
			
			Enjoy </div>
		</div>

		<div className="scheduleBlock">
			<div className="scheduleSubheader">After Party</div>
			<div className="schedulePlace">81 S. Water</div>
			<div className="scheduleTime"><b><i>Saturday, 10:00 PM</i></b></div>
			<div className="scheduleSubheader"></div>
			<div className="activityLoc">For anyone still standing, we will be letting loose at 81 South Water Street with some light bites. We will pass some of Edgartown’s finest watering holes on our way back to the house, which close at 1AM.</div>
			<div className="scheduleSubheader"></div>
			<div className="info"> You know the deal. Drink, dance, repeat.
			</div>
		</div>

		<div className="scheduleBlock">
			<div className="subheader">Brunch</div>
			<div className="schedulePlace">The Edgartown Reading Room</div>
			<div className="schedulePlace"> 1 Cooke Street, Edgartown </div>
			<div className="scheduleTime"><b><i>Sunday, 10:00 AM</i></b></div>
			<div className="scheduleSubheader"></div> 
			<div className="activityLoc">Located a few doors down from the ceremony site, stop by for some coffee and breakfast sandwiches to fuel your journeys home and bid farewell to Mr. & Mrs. Cohen!</div>
			<div className="scheduleSubheader"></div>
			<div className="info"> Please join us for a send-off brunch the morning after the wedding celebrations. The casual buffet-style meal will be very informal, so roll out of bed and head down to the Edgartown Reading Room to indulge in a breakfast spread of food and, if you're brave enough, brunch cocktails, to help replenish all of the energy exerted on the dance floor the night before.
			</div>
			<div className="info">
			The Edgartown Reading Room is also located on South Water Street, a few doors closer to town from the Welcome Party and ceremony site. The clubhouse is at the end of a rock driveway pier and, weather permitting, there will be food and drink stations set up outside on the back wooden deck, so we advise (once again) to choose sensible shoes.
			</div>
			<div className="info">
			While we will be sad the weekend is over, hopefully everyone begins their journey home hangover free with full bellies and fond memories to look back on.   </div>
		</div>
		
		</React.Fragment>    


	);
}

export default Schedule; 