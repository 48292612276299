
import React, {useState} from 'react';
import { Navigate } from 'react-router-dom';
import landing from "../assets/Crest.png"
import "../styles/main.css"; 
import proposal from "../assets/landing.jpg"

function Home () {

  if (!(sessionStorage.getItem('auth-token') == 'hobie')) 
  {
    return (
      <React.Fragment>
        <Navigate to="/pass"/>
      </React.Fragment>
      ); 
  } 

  if (! sessionStorage.getItem('window') && (sessionStorage.getItem('auth-token') == 'hobie'))
  {
    const token2 = 'true';
    window.location.reload(); 
    sessionStorage.setItem('window', token2); 
  }

  return (
    <React.Fragment>
    <div className="">
      <img className="headerImageShrunk" src={landing} alt="landing pic"/>
    </div>
    <div className="headerScript">
      Sarah<span className="invis">p</span> Jordyn Lindenberg
    </div>
    <div className="headerScript">
      and
    </div>
    <div className="headerScript">
      William Edward Cohen
    </div>
    <div className="subheader">
      June 17, 2023
    </div>
    <div className="subheader"> 
      Edgartown, Marthas Vineyard
    </div>

    <div>
      <img className="headerImageFullWidth" src={proposal} alt="proposal"/>
    </div>
    </React.Fragment>
    );
}
  
export default Home;