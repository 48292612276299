import React from "react";
import "../styles/main.css" 
import greybarn from "../assets/greybarn.jpeg"; 
import mgf from "../assets/mgf.png"
import ntnraw from "../assets/ntnraw.jpeg"
import btb from "../assets/btb.jpeg"
import rw from "../assets/rw.jpeg"
import elove from "../assets/elove.jpeg"
import ss from "../assets/ss.jpg"
import alch from "../assets/alch.jpeg"
import detente from "../assets/detente.jpeg"
import bettini from "../assets/bettini.jpeg"
import atria from "../assets/atria.jpeg"
import outermost from "../assets/outermost.jpg"
import greybarncover from "../assets/greybarncover.jpeg"
import signature from "../assets/signatureoyster.jpeg"
import badmartha from "../assets/badmartha.jpeg"
import covington from "../assets/covington.jpeg"
import { Navigate } from 'react-router-dom';

function Activities() {


  if (!(sessionStorage.getItem('auth-token') == 'hobie')) 
  {
    return (
      <React.Fragment>
        <Navigate to="/pass"/>
      </React.Fragment>
      ); 
  } 

	return (
		<React.Fragment>

		<div >
		  <img className="headerImage" src={greybarn} alt="greybarn"/>
		</div> 

		<div className="headerText">
		Things to Do 
		</div>

		<div className="row"></div>

		<div className="subheader">Places to Eat</div>


		<div className="row"> </div>
		<div className="row">
		  <div className="columnLAct">
		  	<div className="row"><a href="https://outermostinn.com/"><img className="iconImg" src={outermost}/></a></div>
			<div className="activityName">The Outermost Inn</div>
			<div className="activityLoc">Aquinnah</div>
			<div className="activityInfo"><div className="tightWrap">A bit of a hike from Edgartown but well worth the journey! Sarah and Will’s go-to for special birthday dinners. Perfect for anyone arriving to the island early for the festivities and looking for some calm before the storm. We recommend scheduling a cab so that you can enjoy cocktails on the lush lawn prior to your reservation.</div></div>
		  </div>
		  <div className="columnAct">
			<div className="row"><a href="https://www.19rawoysterbar.com/"><img className="iconImg" src={ntnraw}/></a></div>
			<div className="activityName">19 Raw</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">Seafood towers, espresso martinis, and scallop crudo, oh my! A fun restaurant in the heart of historic downtown Edgartown. While dinners there are lovely, we think 19 Raw is a prime location for a late afternoon lunch (with cocktails!).</div></div>
		  </div>
		</div>

		<div className="row">
		  <div className="columnLAct">
			<div className="row"><a href="http://www.btbmv.com/"><img className="iconImg" src={btb}/></a></div>
			<div className="activityName">Behind the Bookstore</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">Breakfast, with an iced mint latte, or the Hemingway bar for Al fresco drinks.</div> </div>
		  </div>
		  <div className="columnAct">
		  	<div className="row"><a href="https://www.rosewatermv.com/"><img className="iconImg" src={rw}/></a></div>
			<div className="activityName">Rosewater</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">The breakfast jammie is a must (Will and Sarah like to split one) and the best vanilla latte grandma Pat has ever had.</div> </div>
		  </div>
		</div>

		<div className="row">
		  <div className="columnLAct">
		  	<div className="row"><a href="https://espressolove.com/marthas-vineyard/"><img className="iconImg" src={elove}/></a></div>
			<div className="activityName">Espresso Love</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">Tried and true coffee and sandwich shop located in downtown Edgartown and always open. The Katama chicken rocks.</div></div>
		  </div>
		  <div className="columnAct">
		  	<div className="row"><a href="http://theseafoodshanty.com/"><img className="iconImg" src={ss}/></a></div>
			<div className="activityName">The Seafood Shanty</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">A tourist trap we don't mind being stuck in. Nothing beats a mudslide in the sunshine on the upper deck. </div></div>
		  </div>
		</div>

		<div className="row">
		  <div className="columnLAct">
		  	<div className="row"><a href="https://www.alchemyedgartown.com/"><img className="iconImg" src={alch}/></a></div>
			<div className="activityName">Alchemy</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">Our home away from home. Make sure Jay and the Alchemy ladies -- our second family -- know you’re with us. </div></div>
		  </div>
		  <div className="columnAct">
		  	<div className="row"><a href="https://www.detentemv.com/"><img className="iconImg" src={detente}/></a></div>
			<div className="activityName">Detente</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">Sarah and Will’s favorite dinner spot in Edgartown. The tucked away secret garden atmosphere is magical but the real treat is the delicious and incomparable menu.</div></div>
		  </div>
		</div>

		<div className="row">
		  <div className="columnLAct">
		  	<div className="row"><a href="https://harborviewhotel.com/dining/bettini-restaurant/"><img className="iconImg" src={bettini}/></a></div>
			<div className="activityName">Bettini</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">Conveniently located in the Harborview hotel, for those staying there, Betinni has spectacular views of Edgartown lighthouse. Enjoy an Italian-forward meal overlooking the water.</div></div>
		  </div>
		  <div className="columnAct">
		  	<div className="row"><a href="https://www.atriamv.com/"><img className="iconImg" src={atria}/></a></div>
			<div className="activityName">Atria</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">The best burger in town but Atria is far from a burger joint. Enjoy a fast Eddie Al fresco in their beautiful outdoor dining area.</div></div>
		  </div>
		</div>

		<div className="row">
		  <div className="columnLAct">
		  	<div className="row"><a href="https://www.badmarthabeer.com/"><img className="iconImg" src={badmartha}/></a></div>
			<div className="activityName">Bad Martha's</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">Sarah and Will's favorite spot to grab pizza and a beer, Bad Martha's has a massive patio for you to mingle with peers, children, and dogs whilst enjoying live music.</div></div>
		  </div>
		  <div className="columnAct">
		  	<div className="row"><a href="http://www.thecovingtonrestaurant.com/"><img className="iconImg" src={covington}/></a></div>
			<div className="activityName">The Covington</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">A great restaurant right on main street in Edgartown with unique cocktails and pasta to die for. We recommend sitting outside under the covered porch, but beware the skunks!</div></div>
		  </div>
		</div>

		<div className="row"></div>
		<div className="subheader">Places to Check Out</div>
		<div className="row"></div>

		<div className="row">
		  <div className="columnLAct">

			<div className="row"><a href="https://morninggloryfarm.com/"><img className="iconImg" src={mgf} alt="mgf" /></a></div>
			<div className="activityName">Morning Glory Farm</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">A little farmstand outside of Edgartown and one of our favorite spots. </div></div>
		  </div>
		  <div className="columnAct">
		  	<div className="row"><a href="https://www.thegreybarnandfarm.com/"><img className="iconImg" src={greybarncover} alt="greybarn" /></a></div>
			<div className="activityName">The Grey Barn</div>
			<div className="activityLoc">Chilmark</div>
			<div className="activityInfo"><div className="tightWrap">One of Sarah and Will’s favorite activities, and the source of this page's cover photo, on the island is a Grey Barn cheese tasting, which is how they chose to celebrate the day after their engagement! Surrounded by all the inner island beauty Chilmark has to offer, be prepared to be amazed by the land, the animals, and the poetry-worthy cheese. If tastings are not your thing, you can visit the farm stand, but be sure to get there early for the croissants. </div></div>
		  </div>
		</div>

		<div className="row">
		  <div className="columnLAct">

			<div className="row"><a href="https://www.signatureoysterfarm.com/"><img className="iconImg" src={signature} alt="signature" /></a></div>
			<div className="activityName">Signature Oyster Farm</div>
			<div className="activityLoc">Edgartown</div>
			<div className="activityInfo"><div className="tightWrap">A new addition to Edgartworn, head out for a day of learning about and eating oysters on Signature's farm in Katama Bay. </div></div>
		  </div>
		  <div className="columnAct">
		  </div>
		</div>

		{/* <div className="row">
		  <div className="columnLAct">
		  </div>
		  <div className="columnAct">
		  </div>
		</div> */}

		</React.Fragment>
		)

		
}

export default Activities; 



// <div className="hotelAddress"></div>

