import React from 'react'; 
import { Navigate } from 'react-router-dom';
import registry from "../assets/registry.jpeg"

function Registry(){

  if (!(sessionStorage.getItem('auth-token') == 'hobie') && !(sessionStorage.getItem('auth-token') == 'hobie') )
  {
    return (
      <React.Fragment>
        <Navigate to="/pass"/>
      </React.Fragment>
      ); 
  }

	return (
    <React.Fragment>

		<div >
		  <img className="headerImage" src={registry} alt="registry"/>
		</div> 

		<div className="headerText">
		Registry
		</div>
    <div/>

    <div className="subheader">Sarah & Will’s Non-Traditional Approach</div>
    <div className="info"> 
    Welcome to our registry page! In typical fashion, we are hoping you all will join us in taking a more unconventional and laidback approach to the tradition of wedding gift giving. First and foremost, thank you for being a part of our lives and support system, that is the greatest and only gift we need! With that said, we currently have a cozy living situation, with ~500 sqft of space to share between us and our 80lb puppy (good thing we love each other). </div>
    <span className="invis">I don't know how to web and I'm unwilling to learn</span>
    <div className='info'>
    Because of this, we intentionally have a limited registry. We would ask that you would choose to ship to our address, so that we can schedule the gifts for when we have a firmer idea of our permanent address -- and a larger space! If nothing speaks to you, and you are still wanting to give us something, please consider donating to our future life together. If you have something in mind that you do not see listed here, we trust you and I am sure we will love it!
    </div>
    <span className="invis">There has to be a better way to do this</span>
    <a href="https://overthemoon.com/apps/registry/lindenberg-cohen/experiences"><div className='info'>Link to Registry</div></a>
    <span className="invis"> Seriously what is web </span>
    </React.Fragment>    


  ); 
}

export default Registry; 
